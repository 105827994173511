body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#file {
  display: none;
}

#selectedFileName {
  color: #666 !important;
}

h1 {
  font-size: 64px !important;
}

.center {
  justify-content: center;
}

.center-flex {
  display: flex;
  justify-content: center;
}

.text-concat {
  position: relative;
  display: inline-block;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 5em; /* (Number of lines you want visible) * (line-height) */
  line-height: 1.2em;
  text-align:justify;
}

.h-responsive {
  display: block !important;
  max-width: 100% !important;
  min-height: 300px !important;
}

.byPuGQ {
  border-radius: 1rem;
  box-shadow: rgb(26 45 71 / 10%) 4px 4px 25px 0px;
  background-color: rgb(255, 255, 255);
  padding: 2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  border: 1px solid rgb(224, 224, 224);
}
